<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  passengerFlowListAPI,
  passengerFlowListSummaryAPI,
  customerPriceAnalysisApi,
} from "@/api/shop/report/passengerFlow"; //客流量
export default {
  name: "passengerFlowComponent",
  components: {
    TablePage,
  },
  data() {
    return {
      colorList: [
        "#1891FC",
        "#90CB71",
        "#FAC75A",
        "#F26362",
        "#71C1DE",
        "#389D6E",
        "#FA8550",
        "#9B61B5",
        "#E87BCA",
        "#1890FA",
      ],
      intervalList: [
        {
          value: 20,
          name: "新增会员",
          itemStyle: { color: "#FA8219" },
        },
        {
          value: 50,
          name: "全部会员",
          itemStyle: { color: "#4E95FF" },
        },
        {
          value: 30,
          name: "历史会员",
          itemStyle: { color: "#9477F9" },
        },
      ],
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        isRestTablecolumns: true,
        title: "门店客流量查询",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "dateShop" ||
              //   this.options?.tabColumnType == "timeSlotShop" ||
              //   this.options?.tabColumnType == "shop" ||
              //   this.options?.tabColumnType == "time" ||
              //   this.options?.tabColumnType == "categoryTime" ||
              //   this.options?.tabColumnType == "guest",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "categoryTime" ||
              //   this.options?.tabColumnType == "shop" ||
              //   this.options?.tabColumnType == "time" ||
              //   this.options?.tabColumnType == "guest",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            hide: () => this.options?.tabColumnType == "guest",
            type: "radio",
            label: "",
            filter: "type",
            model: 0,
            defaultValue: 0,
            seniorSearch: true,
            // seniorSearch: true,
            // change: v => {
            //   this.options.columns = this.detailedSummaryFields(
            //     this.options.tabColumnType,
            //     v
            //   )
            //   this.$refs.tablePage.getList()
            // },
            option: {
              data: [
                {
                  label: "均分区间",
                  value: 0,
                },
                {
                  label: "自选区间",
                  value: 1,
                },
              ],
            },
          },
          {
            hide: () =>
              this.options?.tabColumnType == "guest" &&
              this.options?.search[5]?.model === 0,
            type: "equipartitionInterval",
            seniorSearch: true,
            model: {
              begin: 0,
              end: 100,
              number: 10,
            },
            defaultValue: {
              begin: 0,
              end: 100,
              number: 10,
            },
            filterFnc: (e) => e,
            // seniorSearch: true
          },
          {
            hide: () =>
              this.options?.tabColumnType == "guest" &&
              this.options?.search[5]?.model === 1,
            type: "optionalInterval",
            seniorSearch: true,
            filter: "moneyIntervals",
            otherModel: {
              beginMoney: 0,
              endMoney: 100,
            },
            otherDefaultValue: {
              beginMoney: 0,
              endMoney: 100,
            },
            model: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            defaultValue: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            // seniorSearch: true
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "dateShop",
        tabsColumns: [
          {
            defaultBody: { summaryType: 1 },
            getListApi: passengerFlowListAPI,
            getSummaryApi: passengerFlowListSummaryAPI,
            uuid: "79e1ff13-48b9-280f-a092-ccf48894f3f4",
            title: "客流量分析（按日期+分店）",
            type: "dateShop",
            exportOption: {
              fastExportUrl: "/api/system/shop/base/passengerFlow/listExport",
              exportName: "客流量分析（按日期+分店）",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "billMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "customerAverageMoney",
                label: "客单平均金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "thousandCustomerAverageMoney",
                label: "每千客单平均金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: [
              'customerUnitQty',
              "billMoney",
            ],
          },
          {
            title: "客流量分析（按日期+分店+时段）",
            type: "timeSlotShop",
            defaultBody: { summaryType: 2 },
            getListApi: passengerFlowListAPI,
            getSummaryApi: passengerFlowListSummaryAPI,
            uuid: "002f15a2-11db-60b0-b959-cf956c03b032",
            exportOption: {
              fastExportUrl: "/api/system/shop/base/passengerFlow/listExport",
              exportName: "客流量分析（按日期+分店+时段）",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "timeSlotStr",
                label: "时间段",
                minWidth: 150,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "billMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "customerAverageMoney",
                label: "客单平均金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "thousandCustomerAverageMoney",
                label: "每千客单平均金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: [
              'customerUnitQty',
              "billMoney",
            ],
          },
          {
            title: "客流量分析（按分店）",
            type: "shop",
            defaultBody: { summaryType: 3 },
            getListApi: passengerFlowListAPI,
            getSummaryApi: passengerFlowListSummaryAPI,
            uuid: "f3a18551-29a2-8b22-c89a-662080193850",
            exportOption: {
              fastExportUrl: "/api/system/shop/base/passengerFlow/listExport",
              exportName: "客流量分析（按分店）",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "billMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "customerAverageMoney",
                label: "客单平均金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "thousandCustomerAverageMoney",
                label: "每千客单平均金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: [
              "billMoney",
              'customerUnitQty'
            ],
          },
          {
            title: "客流量分析（按时段）",
            type: "time",
            defaultBody: { summaryType: 4 },
            getListApi: passengerFlowListAPI,
            getSummaryApi: passengerFlowListSummaryAPI,
            uuid: "247acec5-b487-16b3-13c4-58ff56c2fd0a",
            exportOption: {
              fastExportUrl: "/api/system/shop/base/passengerFlow/listExport",
              exportName: "客流量分析（按时段）",
            },
            columns: [
              {
                prop: "timeSlotStr",
                label: "时间段",
                minWidth: 150,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "billMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "customerAverageMoney",
                label: "客单平均金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "thousandCustomerAverageMoney",
                label: "每千客单平均金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: [
              'customerUnitQty',
              "billMoney",
            ],
          },
          {
            title: "客流量分析（按类别+时段）",
            type: "categoryTime",
            defaultBody: { summaryType: 5 },
            getListApi: passengerFlowListAPI,
            getSummaryApi: passengerFlowListSummaryAPI,
            uuid: "aaa31841-c452-b5e1-9c23-89db63c5fd8b",
            exportOption: {
              fastExportUrl: "/api/system/shop/base/passengerFlow/listExport",
              exportName: "客流量分析（按类别+时段）",
            },
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "timeSlotStr",
                label: "时间段",
                minWidth: 150,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "billMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "customerAverageMoney",
                label: "客单平均金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "thousandCustomerAverageMoney",
                label: "每千客单平均金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: [
              'customerUnitQty',
              "billMoney",
            ],
          },
          {
            title: "客单价分析",
            type: "guest",
            getListApi: customerPriceAnalysisApi,
            uuid: "5da54237-0ee9-9b2e-f1eb-e86761b20457",
            getSummaryApi: "",
            getDataKey: { list: "data" },
            getEchartsOption: async () => {
              await this.handleEvent("setIntervalList");
              await this.$nextTick();
              return {
                //获取会员类型分布
                tooltip: {
                  trigger: "item", // 提示框触发类型
                  textStyle: {
                    fontSize: 16, // 你可以根据需要调整这个值
                  },
                },
                // legend: {
                //   orient: 'vertical', // 图例列表的布局朝向，默认为水平
                //   right: '30px', // 图例组件离容器右侧的距离
                //   top: 'center', // 图例组件离容器上侧的距离
                //   selectedMode: true, // 关闭图例的点击事件
                //   icon: 'circle'
                // },
                series: [
                  {
                    // name: "Access From", // 系列名称
                    type: "pie", // 图表类型为饼图
                    radius: "50%",
                    center: ["50%", "50%"], // 饼图的中心位置
                    startAngle: 180, // 饼图的起始角度
                    label: {
                      show: true, // 是否显示标签
                      fontSize: 18, // 你可以根据需要调整这个值
                      formatter(param) {
                        console.log("param", param);
                        return `${param.name}占比{color| (${param.percent}%)}`; // 标签的显示格式
                      },
                      color: "#333",
                      // 突出笔数字体和比例字体的样式
                      rich: {
                        color: {
                          fontSize: 18, // 你可以根据需要调整这个值
                          color: "#4E95FF", // 这里设置你希望突出的颜色，例如红色
                        },
                      },
                    },
                    data: this.intervalList,
                  },
                ],
              };
            },
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          this.intervalList = [];
          let list = this.options.list || [];
          list.forEach((range, i) => {
            this.intervalList.push({
              value: range.totalNo,
              name: `${range.moneyIntervals} 元 ${range.totalNo} 笔`,
              itemStyle: { color: this.colorList[i] },
            });
          });
          break;
        default:
          break;
      }
    },
    generateRanges(minModel, maxModel, numModel) {
      const rangeSize = (maxModel - minModel) / numModel;
      const ranges = [];
      for (let i = 0; i < numModel; i++) {
        const startRange = minModel + i * rangeSize;
        const endRange = startRange + rangeSize;
        ranges.push(`${startRange} ~ ${endRange}`);
      }
      return ranges;
    },
  },
};
</script>
