<template>
  <div class="wrap" v-loading="loading">
    <cardTitleCom>
      <template slot="leftCardTitle">
        <span class="fontS14c3 fontBold">整体概览</span>
      </template>
      <template slot="rightCardTitle">
        <div class="x-f">
          <SelectRemote @selectChange='getList' v-model="queryParams.shopId" style="width: 250px" :option="
              $select({
                key: 'listShop',
                option: {
                  option: {
                    placeholder: '全部门店',
                    clearable: true,
                  },
                },
              }).option
            " />
          <el-radio-group v-model="queryParams.filterTime" size="mini" class="filterTimeRadio" @change="getList">
            <el-radio-button label="today">今天</el-radio-button>
            <el-radio-button label="yesterday">昨天</el-radio-button>
            <el-radio-button label="thisWeek">本周</el-radio-button>
            <el-radio-button label="thisMonth">本月</el-radio-button>
            <el-radio-button label="lastMonth">上月</el-radio-button>
          </el-radio-group>

          <!-- <i @click="getList" class="fontS20 cursorP el-icon-refresh-right"></i> -->
        </div>
      </template>
      <template slot="cardContent">
        <div class="dataContent entirety">
          <div class="x-bc marB20">
            <div class="totalAmount y-f">
              <div class="y-c marT20 marB20">
                <span class="titleAmount">{{
                  wholeInfo.totalTransaction || 0
                }}</span>
                <span>交易总额（元）</span>
              </div>
              <div class="x-ac detailMoney marB10">
                <div class="y-c">
                  <span class="titleMoney">{{
                    wholeInfo.vipRecharge || 0
                  }}</span>
                  <span>会员卡充值</span>
                </div>
                <div class="y-c">
                  <span class="titleMoney">{{
                    wholeInfo.orderRealMoney || 0
                  }}</span>
                  <span>预收款</span>
                </div>
                <div class="y-c">
                  <span class="titleMoney">{{
                    fcount([wholeInfo.totalRetail, wholeInfo.receivedMoney], '-') || 0
                  }}</span>
                  <span>零售收款</span>
                </div>
              </div>
              <div style="color: #ff7e28; width:100%; " class="x-b">
                <span></span>
                <span style="margin-right:20px">{{
                  `销售金额(${wholeInfo.totalRetail || 0}) - 已收订金(${
                    wholeInfo.receivedMoney || 0
                  })`
                }}</span>
              </div>
            </div>
            <div class="totalAmount y-f">
              <div class="y-c marT20 marB20">
                <span class="titleAmount">{{
                  wholeInfo.revenueTotalTransaction || 0
                }}</span>
                <span>营收总额（元）</span>
              </div>
              <div class="x-ac detailMoney">
                <div class="y-c">
                  <span class="titleMoney">{{
                    wholeInfo.revenueVipRecharge || 0
                  }}</span>
                  <span>会员卡充值</span>
                </div>
                <div class="y-c">
                  <span class="titleMoney">{{
                    wholeInfo.revenuePredetermine || 0
                  }}</span>
                  <span>预收款</span>
                </div>
                <div class="y-c">
                  <span class="titleMoney">{{
                    wholeInfo.revenueTotalRetail || 0
                  }}</span>
                  <span>零售收款</span>
                </div>
              </div>
            </div>
          </div>
          <div class="marB20">
            <div class="fontS14c3 marB10 fontBold">零售</div>
            <div class="x-ac">
              <div v-for="(item, index) in retailList" :key="index" class="y-c retail">
                <span class="annotateBlue">{{
                  retailInfo[item.price] || 0
                }}</span>
                <span>{{ item.labale }}</span>
              </div>
            </div>
          </div>
          <div class="marB20">
            <div class="fontS14c3 marB10 fontBold">订单</div>
            <div class="x-ac">
              <div v-for="(item, index) in orderList" :key="index" class="x-aw order">
                <div class="titleName" :style="getTitleStyle(index)">
                  {{ item.payType }}
                </div>
                <div v-for="(itemTwo, indexTwo) in item.list" :key="indexTwo" class="y-c orderDetail">
                  <span class="money">{{
                    getOrderMoney(item, itemTwo) || 0
                  }}</span>
                  <span class="fontSize14">{{ itemTwo.labale }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="marB20">
            <div class="fontS14c3 marB10 fontBold">会员</div>
            <div class="x-w">
              <div v-for="(item, index) in viplList" :key="index" class="vipDetail x-fc">
                <div class="y-c left">
                  <span class="marB5">{{ shopVipInfo[item.num] || 0 }}</span>
                  <span>{{ item.name }}</span>
                </div>
                <div class="y-f-j right">
                  <span class="marB10">{{
                    "线上：" + shopVipInfo[item.onLine] || 0
                  }}</span>
                  <span>{{ "线下：" + shopVipInfo[item.offline] || 0 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom :hideCard="true">
      <template slot="cardContent">
        <div style="padding: 20px 10px">
          <div id="chart-container" style="width: 99%; height: 420px"></div>
        </div>
      </template>
    </cardTitleCom>
    <div class="x-f" style="width: 100%">
      <cardTitleCom :hideCard="true" style="width: 50%; margin-right: 10px">
        <template slot="cardContent">
          <div class="proportion">
            <div class="fontS14c3 fontBold">
              <span style="margin-right: 10px" class="cursorP" :class="{ annotateBlue: !queryParams.isRevenue }"
                @click="handleTotal(undefined)">收款统计</span>
              <span class="cursorP" :class="{ annotateBlue: queryParams.isRevenue }"
                @click="handleTotal(true)">营收统计</span>
            </div>
            <div id="chart-proportionMoney" style="width: 100%; height: 300px" />
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom :hideCard="true" style="flex: 1">
        <template slot="cardContent">
          <div class="proportion">
            <div class="fontS14c3 fontBold">会员</div>
            <div class="x-x">
              <div style="width: 450px; height: 300px">
                <div id="chart-vip" style="width: 100%; height: 100%" />
              </div>
              <div style="flex: 1">
                <div class="x-f marB10 detailVip">
                  <div class="dian" style="background: #fa8219"></div>
                  <span>新增会员</span>
                  <span class="addVipClor marLR5">{{
                    vipStatisticsInfo.newVip
                  }}</span>
                  <span>人消费</span>
                  <span class="addVipClor marLR5">{{
                    vipStatisticsInfo.newNo
                  }}</span>
                  <span>单,占比</span>
                  <span class="addVipClor marLR5">{{
                    vipStatisticsInfo.newProportion + "%"
                  }}</span>
                </div>
                <div class="x-f marB10 detailVip">
                  <div class="dian" style="background: #9477f9"></div>
                  <span>历史会员</span>
                  <span class="historyClor marLR5">{{
                    vipStatisticsInfo.oldVip
                  }}</span>
                  <span>人消费</span>
                  <span class="historyClor marLR5">{{
                    vipStatisticsInfo.oldNo
                  }}</span>
                  <span>单,占比</span>
                  <span class="historyClor marLR5">{{
                    vipStatisticsInfo.oldProportion + "%"
                  }}</span>
                </div>
                <div class="x-f detailVip">
                  <div class="dian" style="background: #4e95ff"></div>
                  <span>全部会员</span>
                  <span class="allVipClor marLR5">{{
                    vipStatisticsInfo.allVip
                  }}</span>
                  <span>人消费</span>
                  <span class="allVipClor marLR5">{{
                    vipStatisticsInfo.allNo
                  }}</span>
                  <span>单,占比</span>
                  <span class="allVipClor marLR5">{{
                    vipStatisticsInfo.allProportion + "%"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <div class="x-f" style="width: 100%">
      <cardTitleCom :hideCard="true" style="width: 50%; margin-right: 10px">
        <template slot="cardContent">
          <div class="goodsCategory">
            <div class="marB10 fontS14c3 fontBold">商品零售排行</div>
            <div id="chart-goods" style="width: 100%; height: 420px"></div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom :hideCard="true" style="flex: 1">
        <template slot="cardContent">
          <div class="goodsCategory">
            <div class="marB10 fontS14c3 fontBold">类别零售排行</div>
            <div id="chart-category" style="width: 100%; height: 420px"></div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <div class="x-f" style="width: 100%">
      <cardTitleCom :hideCard="true" style="width: 50%; margin-right: 10px">
        <template slot="cardContent">
          <div class="goodsCategory">
            <div class="marB10 fontS14c3 fontBold">分店零售排行</div>
            <div id="chart-shops" style="width: 100%; height: 420px"></div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom :hideCard="true" style="flex: 1">
        <template slot="cardContent">
          <div class="goodsCategory">
            <div class="marB10 fontS14c3 fontBold">客流排行</div>
            <div id="chart-customerOrder" style="width: 100%; height: 420px"></div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <cardTitleCom :hideCard="true">
      <template slot="cardContent">
        <div style="padding: 20px 10px">
          <div class="fontS14c3 fontBold">销售目标</div>
          <div class="x-aw goalTitle">
            <div class="y-c">
              <span class="goalMoney">{{
                saleTargetInfo.yesterdaySaleMoney || 0
              }}</span>
              <span class="goalLabale">昨日销售额（元）</span>
            </div>
            <div class="y-c">
              <span class="goalMoney">{{
                saleTargetInfo.thisMonthSaleMoney || 0
              }}</span>
              <span class="goalLabale">本月销售额（元）</span>
            </div>
            <div class="y-c">
              <span class="goalMoney">{{
                saleTargetInfo.thisMonthTargetSaleMoney || 0
              }}</span>
              <span class="goalLabale">本月目标销售额（元）</span>
            </div>
            <div class="y-c">
              <span class="goalMoney">{{
                (saleTargetInfo.thisMonthTargetSchedule || 0) + "%"
              }}</span>
              <span class="goalLabale">销售目标完成进度</span>
            </div>
          </div>
          <div id="chart-goal" style="width: 100%; height: 420px"></div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import { fcount } from "@/utils";
import { getTimes } from "@/utils/index.js";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectRemote from "@/components/tablePage/select/select-remote";
import {
  getWhole,
  getRetail,
  getShopOrder,
  getShopVip,
  getOrderTrend,
  getRevenueStatistics,
  getVipStatistics,
  getShopSaleRanking,
  saleTarget,
} from "@/api/shop/report/businessOverview"; //营业概览
export default {
  name: "overview",
  components: { cardTitleCom, SelectRemote },
  data() {
    return {
      myChartGoods: undefined,
      rankingTypeGoodsInfo: {},
      rankingTypeCategoryInfo: {},
      rankingTypeShopInfo: {},
      rankingTypeOneInfo: {},
      saleTargetInfo: {},
      vipStatisticsInfo: {},
      initChartChart: {},
      revenueStatisticsInfo: [],
      orderTrendInfo: [],
      shopVipInfo: {},
      shopOrderInfo: [],
      retailInfo: {},
      loading: false,
      wholeInfo: {},
      xGoal: [
        "2024-01-01",
        "2024-01-02",
        "2024-01-03",
        "2024-01-04",
        "2024-01-05",
      ],
      yGoal: [30, 20, 60, 80, 72],
      xAxisData: [
        "0:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ], // 消费趋势初始化 x 轴数据
      seriesData: [
        10, 20, 50, 20, 60, 60, 60, 80, 120, 70, 30, 80, 60, 50, 60, 90, 30, 60,
        70, 60, 60, 60, 60, 60,
      ], // 初始化系列数据
      myChart: undefined,
      queryParams: { filterTime: "today", isRevenue: undefined },
      retailList: [
        {
          price: "customerPrice",
          labale: "客单价",
        },
        {
          price: "customerNumber",
          labale: "客单数",
        },
        {
          price: "customerMeasure",
          labale: "客单量",
        },
        {
          price: "saleQty",
          labale: "销售数量",
        },
        {
          price: "saleMoney",
          labale: "销售金额",
        },
        {
          price: "returnMoney",
          labale: "退货金额",
        },
        {
          price: "returnNumber",
          labale: "退货数量",
        },
        {
          price: "agioMoney",
          labale: "优惠总金额",
        },
      ],
      orderList: [
        {
          payType: "门店预定",
          payTypeId: 3,
          list: [
            {
              labale: "订单实收（元）",
              value: "orderNetReceipts",
            },
            {
              labale: "支付订单总数",
              value: "payOrderNumber",
            },
            {
              labale: "退货单数",
              value: "returnNumber",
            },
            {
              labale: "退货金额",
              value: "returnMoney",
            },
          ],
        },
        {
          payType: "自营商城",
          payTypeId: 5,
          list: [
            {
              labale: "订单实收（元）",
              value: "orderNetReceipts",
            },
            {
              labale: "支付订单总数",
              value: "payOrderNumber",
            },
            {
              labale: "退货单数",
              value: "returnNumber",
            },
            {
              labale: "退货金额",
              value: "returnMoney",
            },
          ],
        },
        {
          payType: "美团",
          payTypeId: 6,
          list: [
            {
              labale: "订单实收（元）",
              value: "orderNetReceipts",
            },
            {
              labale: "支付订单总数",
              value: "payorderNumber",
            },
            {
              labale: "退货单数",
              value: "returnNumber",
            },
            {
              labale: "退货金额",
              value: "returnMoney",
            },
          ],
        },
        {
          payType: "饿了么",
          payTypeId: 7,
          list: [
            {
              labale: "订单实收（元）",
              value: "orderNetReceipts",
            },
            {
              labale: "支付订单总数",
              value: "payorderNumber",
            },
            {
              labale: "退货单数",
              value: "returnNumber",
            },
            {
              labale: "退货金额",
              value: "returnMoney",
            },
          ],
        },
      ],
      viplList: [
        {
          name: "新增会员",
          num: "newVip",
          onLine: "newVipOnline",
          offline: "newVipOffline",
        },
        {
          name: "会员卡充值",
          num: "vipCardRecharge",
          onLine: "vipCardRechargeOnline",
          offline: "vipCardRechargeOffline",
        },
        {
          name: "会员充值客单价",
          num: "vipRechargePrice",
          onLine: "vipRechargePriceOnline",
          offline: "vipRechargePriceOffline",
        },
        {
          name: "会员充值客单数",
          num: "vipRechargeSum",
          onLine: "vipRechargeSumOnline",
          offline: "vipRechargeSumOffline",
        },
        {
          name: "会员贡献率（%）",
          num: "vipContributionRateSum",
          onLine: "vipContributionRateSumOnline",
          offline: "vipContributionRateSumOffline",
        },
        {
          name: "会员卡消费",
          num: "vipConsumption",
          onLine: "vipConsumptionOnline",
          offline: "vipConsumptionOffline",
        },
        {
          name: "会员消费客单价",
          num: "vipConsumptionPrice",
          onLine: "vipConsumptionPriceOnline",
          offline: "vipConsumptionPriceOffline",
        },
        {
          name: "会员消费购物笔数",
          num: "vipConsumptionSum",
          onLine: "vipConsumptionSumOnline",
          offline: "vipConsumptionSumOffline",
        },
      ],
    };
  },
  async mounted() {
    await this.getList(true);
  },
  computed: {
    getOrderMoney() {
      return (item, itemTwo) => {
        const payType = this.shopOrderInfo.find(
          (v) => v.payTypeId == item.payTypeId
        );
        return payType ? payType[itemTwo.value] : 0;
      };
    },
    getTitleStyle() {
      return (index) => {
        // 根据索引返回相应的样式
        // 示例：假设getStyle是根据index返回样式的函数
        return this.getStyle(index);
      };
    },
  },
  watch: {
    //门店日期
    "queryParams.filterTime": {
      handler(newVal, oldVal) {
        if (newVal) {
          try {
            let datetime = getTimes({
              type: newVal,
              format: "datetimerange",
            }).filter((ite) => typeof ite !== "undefined");
            this.queryParams.beginBillDate = datetime[0];
            this.queryParams.endBillDate = datetime[1];
          } catch (error) { }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fcount,
    async handleTotal(BOLER) {
      try {
        this.loading = true;
        this.queryParams.isRevenue = BOLER;
        const res = await getRevenueStatistics(this.queryParams);
        this.revenueStatisticsInfo = res.data || [];
        this.proportionMoney(false);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    //刷新页面
    async getList(bol = false) {
      try {
        if (!this.loading) {
          this.loading = true;
        }
        // 并发执行所有请求
        const promises = [
          getWhole(this.queryParams),
          getRetail(this.queryParams),
          getShopOrder(this.queryParams),
          getShopVip(this.queryParams),
          getOrderTrend(this.queryParams),
          getRevenueStatistics(this.queryParams),
          getVipStatistics(this.queryParams),
          saleTarget(this.queryParams),
          getShopSaleRanking({ ...this.queryParams, rankingType: 1 }),
          getShopSaleRanking({ ...this.queryParams, rankingType: 2 }),
          getShopSaleRanking({ ...this.queryParams, rankingType: 3 }),
          getShopSaleRanking({ ...this.queryParams, rankingType: 4 }),
        ];

        // 等待所有请求完成，并获取结果
        const results = await Promise.all(promises);

        // 处理结果
        this.wholeInfo = results[0].data || {};
        this.retailInfo = results[1].data || {};
        this.shopOrderInfo = results[2].data || [];
        this.shopVipInfo = results[3].data || {};
        this.orderTrendInfo = results[4].data || [];
        this.revenueStatisticsInfo = results[5].data || [];
        this.vipStatisticsInfo =
          results[6].data?.length > 0 ? results[6].data[0] : {};
        this.saleTargetInfo = results[7].data || {};
        this.rankingTypeGoodsInfo = results[8].data || {};
        this.rankingTypeCategoryInfo = results[9].data || {};
        this.rankingTypeShopInfo = results[10].data || {};
        this.rankingTypeOneInfo = results[11].data || {};

        // 调用初始化函数
        this.initChart(bol);
        this.proportionMoney(bol);
        this.vipInitChart(bol);
        this.initChartGoal(bol);
        this.initChartGoods(bol);
        this.initChartCategory(bol);
        this.initChartShops(bol);
        this.initChartCustomerOrder(bol);
      } catch (error) {
        // 错误处理
      } finally {
        this.loading = false;
      }
    },
    getStyle(index) {
      let backgroundColor = "";
      let color = "";

      switch (index) {
        case 0:
          backgroundColor = "#0fc464";
          color = "#fff";
          break;
        case 1:
          backgroundColor = "#fa8219";
          color = "#fff";
          break;
        case 2:
          backgroundColor = "#f3b804";
          color = "#333";
          break;
        default:
          backgroundColor = "#409eff";
          color = "#fff";
          break;
      }

      return {
        backgroundColor: backgroundColor,
        color: color,
      };
    },
    //折线
    initChart(bol = true) {
      if (bol) {
        if (this.myChart) {
          this.myChart.dispose(); // 销毁之前的实例
        }
        const chartContainer = document.getElementById("chart-container");
        this.myChart = this.$echarts.init(chartContainer);
      }
      this.initChartChart = {
        dateStrArr: this.orderTrendInfo.map((item) => item.dateStr) || [],
        noNumberArr: this.orderTrendInfo.map((item) => item.noNumber) || [],
        payMoneyArr: this.orderTrendInfo.map((item) => item.payMoney) || [],
      };
      let noNumberMax =
        Math.ceil(Math.max.apply(null, this.initChartChart.noNumberArr) / 10) *
        10;
      noNumberMax = noNumberMax >= 10 ? noNumberMax : 10;
      let payMoneyMax =
        Math.ceil(Math.max.apply(null, this.initChartChart.payMoneyArr) / 10) *
        10;
      payMoneyMax = payMoneyMax >= 10 ? payMoneyMax : 10;
      let noNumberInterval = (noNumberMax - 0) / 5;
      let payMoneyInterval = (payMoneyMax - 0) / 5;
      // 设置图表配置
      const option = {
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `${params[0].name}<br>${params[0].marker}营业额:${params[0].data}(元)<br>${params[1].marker}订单数:${params[1].data}(笔)`; // 提示框内容格式化
          },
        },
        legend: {
          data: ["营业额", "订单数"], // 图例名称
          itemWidth: 30, // 设置图例项的宽度，增加线的长度
          //itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#333", // 图例文本颜色
          },
        },
        grid: {
          left: "1%", // 图表左边空白
          right: "1%", // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this.initChartChart.dateStrArr, // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: false, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#cccccc", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            min: 0,
            max: payMoneyMax,
            interval: payMoneyInterval,
            type: "value", // y 轴类型为数值轴
            name: "营业额(元)", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            min: 0,
            max: noNumberMax,
            interval: noNumberInterval,
            type: "value", // y 轴类型为数值轴
            name: "订单数(笔)", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
        ],
        series: [
          {
            name: "营业额", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.initChartChart.payMoneyArr, // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#40B5FF", // 数据项样式
            },
            lineStyle: {
              color: "#40B5FF", // 折线样式
            },
          },
          {
            name: "订单数", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.initChartChart.noNumberArr, // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#FA8219", // 数据项样式
            },
            lineStyle: {
              color: "#FA8219", // 折线样式
            },
          },
        ],
      };
      // 设置图表配置
      this.myChart.setOption(option);
      if (bol) {
        // 监听窗口变化，调整图表大小
        window.addEventListener("resize", () => {
          this.myChart.resize();
        });
      }
    },
    //统计
    proportionMoney(bol = true) {
      if (bol) {
        if (this.proportionMoneyChart) {
          this.proportionMoneyChart.dispose(); // 销毁之前的实例
        }
        const chartStyle = document.getElementById("chart-proportionMoney"); //获取会员类型分布
        this.proportionMoneyChart = this.$echarts.init(chartStyle); //初始化会员类型分布
      }
      // 使用 echarts 渲染图表
      this.proportionMoneyChart.setOption({
        //获取会员类型分布
        tooltip: {
          trigger: "item", // 提示框触发类型
          textStyle: {
            fontSize: 14, // 你可以根据需要调整这个值
          },
        },
        legend: {
          orient: "vertical", // 图例列表的布局朝向，默认为水平
          right: "30px", // 图例组件离容器右侧的距离
          top: "center", // 图例组件离容器上侧的距离
          selectedMode: true, // 关闭图例的点击事件
          icon: "circle",
        },
        series: [
          {
            // name: "Access From", // 系列名称
            type: "pie", // 图表类型为饼图
            radius: ["40%", "50%"], // 饼图的内外半径
            center: ["50%", "50%"], // 饼图的中心位置
            startAngle: 180, // 饼图的起始角度
            label: {
              show: true, // 是否显示标签
              fontSize: 16, // 你可以根据需要调整这个值
              formatter(param) {
                return `${param.name}占比{color| (${param.percent}%)}`;
              },
              color: "#333",
              // 突出笔数字体和比例字体的样式
              rich: {
                color: {
                  fontSize: 16, // 你可以根据需要调整这个值
                  color: "#4E95FF", // 这里设置你希望突出的颜色，例如红色
                },
              },
            },
            data: this.revenueStatisticsInfo.map((v) => {
              return {
                value: v.payMoney,
                name: v.payModeName,
                itemStyle: { color: this.getRandomColor() },
              };
            }),
          },
        ],
      });
      if (bol) {
        // 监听窗口大小变化，实现图表的自适应
        window.addEventListener("resize", () => {
          this.proportionMoneyChart.resize();
        });
      }
    },
    //随机生成
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    //会员
    vipInitChart(bol = true) {
      if (bol) {
        if (this.myVipChart) {
          this.myVipChart.dispose(); // 销毁之前的实例
        }
        const chartStyle = document.getElementById("chart-vip"); //获取会员类型分布
        this.myVipChart = this.$echarts.init(chartStyle); //初始化会员类型分布
      }
      // 使用 echarts 渲染图表
      this.myVipChart.setOption({
        //获取会员类型分布
        tooltip: {
          trigger: "item", // 提示框触发类型
          textStyle: {
            fontSize: 16, // 你可以根据需要调整这个值
          },
        },
        // legend: {
        //   orient: 'vertical', // 图例列表的布局朝向，默认为水平
        //   right: '30px', // 图例组件离容器右侧的距离
        //   top: 'center', // 图例组件离容器上侧的距离
        //   selectedMode: true, // 关闭图例的点击事件
        //   icon: 'circle'
        // },
        series: [
          {
            // name: "Access From", // 系列名称
            type: "pie", // 图表类型为饼图
            radius: ["40%", "50%"], // 饼图的内外半径
            center: ["50%", "50%"], // 饼图的中心位置
            startAngle: 180, // 饼图的起始角度
            label: {
              show: true, // 是否显示标签
              fontSize: 14, // 你可以根据需要调整这个值
              formatter(param) {
                return `${param.name}{color| (${param.percent}%)}`;
              },
              color: "#333",
              // 突出笔数字体和比例字体的样式
              rich: {
                color: {
                  fontSize: 14, // 你可以根据需要调整这个值
                  color: "#4E95FF", // 这里设置你希望突出的颜色，例如红色
                },
              },
            },
            data: [
              {
                value: this.vipStatisticsInfo.newProportion,
                name: "新增会员",
                itemStyle: { color: "#FA8219" },
              },
              {
                value: (
                  100 -
                  this.vipStatisticsInfo.newProportion -
                  this.vipStatisticsInfo.newProportion
                ).toFixed(2),
                name: "其他",
                itemStyle: { color: "#4E95FF" },
              },
              {
                value: this.vipStatisticsInfo.oldProportion,
                name: "历史会员",
                itemStyle: { color: "#9477F9" },
              },
            ],
          },
        ],
      });
      if (bol) {
        // 监听窗口大小变化，实现图表的自适应
        window.addEventListener("resize", () => {
          this.myVipChart.resize();
        });
      }
    },
    vipInitChart(bol = true) {
      if (bol) {
        if (this.myVipChart) {
          this.myVipChart.dispose(); // 销毁之前的实例
        }
        const chartStyle = document.getElementById("chart-vip"); //获取会员类型分布
        this.myVipChart = this.$echarts.init(chartStyle); //初始化会员类型分布
      }
      // 使用 echarts 渲染图表
      this.myVipChart.setOption({
        //获取会员类型分布
        tooltip: {
          trigger: "item", // 提示框触发类型
          textStyle: {
            fontSize: 16, // 你可以根据需要调整这个值
          },
          formatter(param) {
            return `${param.name}${param.percent}%`;
          },
        },
        // legend: {
        //   orient: 'vertical', // 图例列表的布局朝向，默认为水平
        //   right: '30px', // 图例组件离容器右侧的距离
        //   top: 'center', // 图例组件离容器上侧的距离
        //   selectedMode: true, // 关闭图例的点击事件
        //   icon: 'circle'
        // },
        series: [
          {
            // name: "Access From", // 系列名称
            type: "pie", // 图表类型为饼图
            radius: ["40%", "50%"], // 饼图的内外半径
            center: ["50%", "50%"], // 饼图的中心位置
            startAngle: 180, // 饼图的起始角度
            label: {
              show: true, // 是否显示标签
              fontSize: 14, // 你可以根据需要调整这个值
              formatter(param) {
                return `${param.name}{color| (${param.percent}%)}`;
              },
              color: "#333",
              // 突出笔数字体和比例字体的样式
              rich: {
                color: {
                  fontSize: 14, // 你可以根据需要调整这个值
                  color: "#4E95FF", // 这里设置你希望突出的颜色，例如红色
                },
              },
            },
            data: [
              {
                value: this.vipStatisticsInfo.newProportion,
                name: "新增会员",
                itemStyle: { color: "#FA8219" },
              },
              {
                value: (
                  100 -
                  this.vipStatisticsInfo.newProportion -
                  this.vipStatisticsInfo.oldProportion
                ).toFixed(2),
                name: "其他",
                itemStyle: { color: "#4E95FF" },
              },
              {
                value: this.vipStatisticsInfo.oldProportion,
                name: "历史会员",
                itemStyle: { color: "#9477F9" },
              },
            ],
          },
        ],
      });
      if (bol) {
        // 监听窗口大小变化，实现图表的自适应
        window.addEventListener("resize", () => {
          this.myVipChart.resize();
        });
      }
    },
    //目标
    initChartGoal(bol) {
      if (bol) {
        if (this.myGoalChart) {
          this.myGoalChart.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-goal");
        this.myGoalChart = this.$echarts.init(chartContainerAdd);
      }
      //会员增长
      this.myGoalChart.setOption({
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "shadow", // 修改为柱状图的提示框样式
          // },
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              "日期：" +
              params[0].name +
              "<br>" +
              params[0].marker +
              `销售额：${params[0].data} (元)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data:
              this.saleTargetInfo?.saleTargetDetailsRespList.map(
                (x) => x.billDateStr
              ) || [],
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#999999",
              },
            },
            // boundaryGap: true, // 在柱状图中通常需要设置为 true
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value",

            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        series: [
          {
            type: "bar", // 将折线图改为柱状图
            data:
              this.saleTargetInfo?.saleTargetDetailsRespList.map(
                (x) => x.payMoney
              ) || [], // 将柱状图的数据设置为 YData
            barWidth: "15%",
            itemStyle: {
              color: "#4E95FF",
            },
          },
        ],
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myGoalChart.resize();
        });
      }
    },
    //商品零售排行
    initChartGoods(bol) {
      let nameList = [];
      let qtyList = [];
      this.rankingTypeGoodsInfo.sort((a, b) => a.qty - b.qty);
      this.rankingTypeGoodsInfo.forEach((item) => {
        nameList.push(item.name);
        qtyList.push(item.qty);
      });
      if (bol) {
        if (this.myChartGoods) {
          this.myChartGoods.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-goods");
        this.myChartGoods = this.$echarts.init(chartContainerAdd);
      }
      this.myChartGoods.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              "名称：" +
              params[0].name +
              "<br>" +
              params[0].marker +
              `销售额：${params[0].data} (元)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        yAxis: [
          {
            type: "category",
            data: nameList,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            boundaryGap: true,
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        xAxis: [
          {
            show: false,
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: qtyList,
            barWidth: "12px",
            itemStyle: {
              color: "#4E95FF",
            },
            label: {
              show: true,
              position: "right",
              color: "#000",
              fontSize: 12,
            },
          },
        ],
        dataZoom: [
          {
            width: 10, // 设置滚动条的
            yAxisIndex: 0,
            top: "5%", // 控制滚动条在顶部
            zoomOnMouseWheel: true,
            type: "slider",
            showDetail: false,
            // 数据窗口范围的起始数值
            startValue: qtyList.length - 1,
            // 数据窗口范围的结束数值（一页显示多少条数据，从0开始）
            endValue: qtyList.length - 11,
            filterMode: "empty",
            zoomLoxk: true,
            brushSelect: false, //刷选功能
            borderRadius: 3,
            showDataShadow: false, //是否显示数据阴影 默认auto
          },
        ],
      });
      this.myChartGoods.getZr().on("mousewheel", (params) => {
        params.event.preventDefault();
        params.event.stopPropagation();
        const deltaY = params.event.wheelDeltaY;
        const dataZoomOption = {
          type: "dataZoom",
          dataZoomIndex: 0,
          with: 10, // 设置滚动条的
        };
        const currentDataZoom = this.myChartGoods.getModel().getOption()
          .dataZoom[0];
        const range = currentDataZoom.end - currentDataZoom.start;
        let newStart = currentDataZoom.start;
        let newEnd = currentDataZoom.end;

        if (deltaY > 0) {
          newStart = Math.max(0, currentDataZoom.start - range * 0.1); // 向上滚动
          newEnd = newStart + range;
        } else {
          newEnd = Math.min(100, currentDataZoom.end + range * 0.1); // 向下滚动
          newStart = newEnd - range;
        }

        dataZoomOption.start = newStart;
        dataZoomOption.end = newEnd;
        this.myChartGoods.dispatchAction(dataZoomOption);
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myChartGoods.resize();
        });
      }
    },
    //类别零售排行
    initChartCategory(bol) {
      let nameList = [];
      let qtyList = [];
      this.rankingTypeCategoryInfo.sort((a, b) => a.qty - b.qty);
      this.rankingTypeCategoryInfo.forEach((item) => {
        nameList.push(item.name);
        qtyList.push(item.qty);
      });
      if (bol) {
        if (this.myChartCategory) {
          this.myChartCategory.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-category");
        this.myChartCategory = this.$echarts.init(chartContainerAdd);
      }
      this.myChartCategory.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              "名称：" +
              params[0].name +
              "<br>" +
              params[0].marker +
              `销售额：${params[0].data} (元)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        yAxis: [
          {
            type: "category",
            data: nameList,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            boundaryGap: true,
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        xAxis: [
          {
            show: false,
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: qtyList,
            barWidth: "12px",
            itemStyle: {
              color: "#4E95FF",
            },
            label: {
              show: true,
              position: "right",
              color: "#000",
              fontSize: 12,
            },
          },
        ],
        dataZoom: [
          {
            width: 10, // 设置滚动条的
            yAxisIndex: 0,
            top: "5%", // 控制滚动条在顶部
            zoomOnMouseWheel: true,
            type: "slider",
            showDetail: false,
            // 数据窗口范围的起始数值
            startValue: qtyList.length - 1,
            // 数据窗口范围的结束数值（一页显示多少条数据，从0开始）
            endValue: qtyList.length - 11,
            filterMode: "empty",
            zoomLoxk: true,
            brushSelect: false, //刷选功能
            borderRadius: 3,
            showDataShadow: false, //是否显示数据阴影 默认auto
          },
        ],
      });
      this.myChartCategory.getZr().on("mousewheel", (params) => {
        params.event.preventDefault();
        params.event.stopPropagation();
        const deltaY = params.event.wheelDeltaY;
        const dataZoomOption = {
          type: "dataZoom",
          dataZoomIndex: 0,
          with: 10, // 设置滚动条的
        };
        const currentDataZoom = this.myChartCategory.getModel().getOption()
          .dataZoom[0];
        const range = currentDataZoom.end - currentDataZoom.start;
        let newStart = currentDataZoom.start;
        let newEnd = currentDataZoom.end;

        if (deltaY > 0) {
          newStart = Math.max(0, currentDataZoom.start - range * 0.1); // 向上滚动
          newEnd = newStart + range;
        } else {
          newEnd = Math.min(100, currentDataZoom.end + range * 0.1); // 向下滚动
          newStart = newEnd - range;
        }

        dataZoomOption.start = newStart;
        dataZoomOption.end = newEnd;
        this.myChartCategory.dispatchAction(dataZoomOption);
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myChartCategory.resize();
        });
      }
    },
    //分店零售排行
    initChartShops(bol) {
      let nameList = [];
      let qtyList = [];
      this.rankingTypeShopInfo.sort((a, b) => a.qty - b.qty);
      this.rankingTypeShopInfo.forEach((item) => {
        nameList.push(item.name);
        qtyList.push(item.qty);
      });
      if (bol) {
        if (this.myChartShops) {
          this.myChartShops.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-shops");
        this.myChartShops = this.$echarts.init(chartContainerAdd);
      }
      this.myChartShops.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              "名称：" +
              params[0].name +
              "<br>" +
              params[0].marker +
              `销售额：${params[0].data} (元)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        yAxis: [
          {
            type: "category",
            data: nameList,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            boundaryGap: true,
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        xAxis: [
          {
            show: false,
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: qtyList,
            barWidth: "12px",
            itemStyle: {
              color: "#4E95FF",
            },
            label: {
              show: true,
              position: "right",
              color: "#000",
              fontSize: 12,
            },
          },
        ],
        dataZoom: [
          {
            width: 10, // 设置滚动条的
            yAxisIndex: 0,
            top: "5%", // 控制滚动条在顶部
            zoomOnMouseWheel: true,
            type: "slider",
            showDetail: false,
            // 数据窗口范围的起始数值
            startValue: qtyList.length - 1,
            // 数据窗口范围的结束数值（一页显示多少条数据，从0开始）
            endValue: qtyList.length - 11,
            filterMode: "empty",
            zoomLoxk: true,
            brushSelect: false, //刷选功能
            borderRadius: 3,
            showDataShadow: false, //是否显示数据阴影 默认auto
          },
        ],
      });
      this.myChartShops.getZr().on("mousewheel", (params) => {
        params.event.preventDefault();
        params.event.stopPropagation();
        const deltaY = params.event.wheelDeltaY;
        const dataZoomOption = {
          type: "dataZoom",
          dataZoomIndex: 0,
          with: 10, // 设置滚动条的
        };
        const currentDataZoom = this.myChartShops.getModel().getOption()
          .dataZoom[0];
        const range = currentDataZoom.end - currentDataZoom.start;
        let newStart = currentDataZoom.start;
        let newEnd = currentDataZoom.end;

        if (deltaY > 0) {
          newStart = Math.max(0, currentDataZoom.start - range * 0.1); // 向上滚动
          newEnd = newStart + range;
        } else {
          newEnd = Math.min(100, currentDataZoom.end + range * 0.1); // 向下滚动
          newStart = newEnd - range;
        }

        dataZoomOption.start = newStart;
        dataZoomOption.end = newEnd;
        this.myChartShops.dispatchAction(dataZoomOption);
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myChartShops.resize();
        });
      }
    },
    //客单占比排行
    initChartCustomerOrder(bol) {
      let nameList = [];
      let qtyList = [];
      this.rankingTypeOneInfo.sort((a, b) => a.qty - b.qty);
      this.rankingTypeOneInfo.forEach((item) => {
        nameList.push(item.name);
        qtyList.push(item.qty);
      });
      if (bol) {
        if (this.myChartCustomerOrder) {
          this.myChartCustomerOrder.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById(
          "chart-customerOrder"
        );
        this.myChartCustomerOrder = this.$echarts.init(chartContainerAdd);
      }
      this.myChartCustomerOrder.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              "名称：" +
              params[0].name +
              "<br>" +
              params[0].marker +
              `客单数：${params[0].data} (个)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        yAxis: [
          {
            type: "category",
            data: nameList,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            boundaryGap: true,
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        xAxis: [
          {
            show: false,
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: qtyList,
            barWidth: "12px",
            itemStyle: {
              color: "#4E95FF",
            },
            label: {
              show: true,
              position: "right",
              color: "#000",
              fontSize: 12,
            },
          },
        ],
        dataZoom: [
          {
            width: 10, // 设置滚动条的
            yAxisIndex: 0,
            top: "5%", // 控制滚动条在顶部
            zoomOnMouseWheel: true,
            type: "slider",
            showDetail: false,
            // 数据窗口范围的起始数值
            startValue: qtyList.length - 1,
            // 数据窗口范围的结束数值（一页显示多少条数据，从0开始）
            endValue: qtyList.length - 11,
            filterMode: "empty",
            zoomLoxk: true,
            brushSelect: false, //刷选功能
            borderRadius: 3,
            showDataShadow: false, //是否显示数据阴影 默认auto
          },
        ],
      });
      this.myChartCustomerOrder.getZr().on("mousewheel", (params) => {
        params.event.preventDefault();
        params.event.stopPropagation();
        const deltaY = params.event.wheelDeltaY;
        const dataZoomOption = {
          type: "dataZoom",
          dataZoomIndex: 0,
          with: 10, // 设置滚动条的
        };
        const currentDataZoom = this.myChartCustomerOrder.getModel().getOption()
          .dataZoom[0];
        const range = currentDataZoom.end - currentDataZoom.start;
        let newStart = currentDataZoom.start;
        let newEnd = currentDataZoom.end;

        if (deltaY > 0) {
          newStart = Math.max(0, currentDataZoom.start - range * 0.1); // 向上滚动
          newEnd = newStart + range;
        } else {
          newEnd = Math.min(100, currentDataZoom.end + range * 0.1); // 向下滚动
          newStart = newEnd - range;
        }

        dataZoomOption.start = newStart;
        dataZoomOption.end = newEnd;
        this.myChartCustomerOrder.dispatchAction(dataZoomOption);
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myChartCustomerOrder.resize();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  padding: 10px 10px 5px 10px;
  color: #333;
  .filterTimeRadio {
    margin: 0 10px;
  }
  .el-icon-refresh-right {
    margin-right: 10px;
  }
  .dataContent {
    padding: 20px 20px;
  }
  .entirety {
    //交易
    .totalAmount {
      width: 49.5%;
      height: 220px;
      border-radius: 3px;
      background-color: #f7f8f9;
      .titleAmount {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 38px;
      }
      .detailMoney {
        width: 100%;
        .titleMoney {
          font-family: Microsoft YaHei;
          font-size: 26px;
          font-weight: 400;
        }
        .y-c {
          width: 33.333%;
        }
        .y-c:nth-child(1),
        .y-c:nth-child(2) {
          border-right: 1px solid #bfbfbf;
        }
      }
    }
    .retail {
      margin-right: 10px;
      width: 100%;
      height: 85px;
      background-color: #f3f9ff;
    }
    .retail:nth-child(8) {
      margin-right: 0;
    }
    .order {
      padding: 10px 15px;
      position: relative;
      height: 176px;
      width: 100%;
      background-color: #f7f8f9;
      margin-right: 10px;

      .orderDetail {
        width: 50%;
        .money {
          font-size: 20px;
          font-weight: 400;
          font-family: Microsoft YaHei;
        }
      }
      .orderDetail:nth-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }
      .orderDetail:nth-child(3) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .order:nth-child(4) {
      margin-right: 0;
    }
    .titleName {
      top: 0;
      left: 0;
      position: absolute;
      height: 22px;
      width: 62px;
      font-size: 12px;
      text-align: center;
      line-height: 22px;
      border-bottom-right-radius: 14px;
      color: #fff;
    }
    .vipDetail {
      height: 83px;
      width: calc(25% - 7.5px);
      margin: 0 10px 10px 0;
      background-color: #fffaec;
      .left {
        width: 50%;
        border-right: 1px solid #ffdca6;
      }
      .right {
        width: 50%;
        padding-left: 44px;
      }
    }
    .vipDetail:nth-child(4),
    .vipDetail:nth-child(8) {
      margin-right: 0;
    }
  }
  .proportion {
    padding: 20px 10px;
    width: 100%;
    .detailVip {
      width: 100%;
      height: 80px;
      font-size: 14px;
      line-height: 70px;
      background: #f7f8f9;
      text-align: center;
      border-radius: 5px;
      .dian {
        width: 12px;
        height: 12px;
        margin: 0 10px;
        background: #fa8219;
        border-radius: 50%;
      }
      .addVipClor {
        color: #fa8219;
      }
      .historyClor {
        color: #9477f9;
      }
      .allVipClor {
        color: #4e95ff;
      }
      .marLR5 {
        margin: 0 5px;
      }
    }
  }
  .goalTitle {
    width: 100%;
    margin-top: 20px;
    .goalMoney {
      font-weight: 400;
      font-size: 36px;
    }
    .goalLabale {
      font-size: 16px;
      color: #999999;
    }
  }
  .goodsCategory {
    padding: 20px 10px;
    width: 100%;
  }
}
</style>
