var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "cardTitleCom",
        [
          _c("template", { slot: "leftCardTitle" }, [
            _c("span", { staticClass: "fontS14c3 fontBold" }, [
              _vm._v("整体概览"),
            ]),
          ]),
          _c("template", { slot: "rightCardTitle" }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("SelectRemote", {
                  staticStyle: { width: "250px" },
                  attrs: {
                    option: _vm.$select({
                      key: "listShop",
                      option: {
                        option: {
                          placeholder: "全部门店",
                          clearable: true,
                        },
                      },
                    }).option,
                  },
                  on: { selectChange: _vm.getList },
                  model: {
                    value: _vm.queryParams.shopId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "shopId", $$v)
                    },
                    expression: "queryParams.shopId",
                  },
                }),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "filterTimeRadio",
                    attrs: { size: "mini" },
                    on: { change: _vm.getList },
                    model: {
                      value: _vm.queryParams.filterTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "filterTime", $$v)
                      },
                      expression: "queryParams.filterTime",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "today" } }, [
                      _vm._v("今天"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "yesterday" } }, [
                      _vm._v("昨天"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "thisWeek" } }, [
                      _vm._v("本周"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "thisMonth" } }, [
                      _vm._v("本月"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "lastMonth" } }, [
                      _vm._v("上月"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "dataContent entirety" }, [
              _c("div", { staticClass: "x-bc marB20" }, [
                _c("div", { staticClass: "totalAmount y-f" }, [
                  _c("div", { staticClass: "y-c marT20 marB20" }, [
                    _c("span", { staticClass: "titleAmount" }, [
                      _vm._v(_vm._s(_vm.wholeInfo.totalTransaction || 0)),
                    ]),
                    _c("span", [_vm._v("交易总额（元）")]),
                  ]),
                  _c("div", { staticClass: "x-ac detailMoney marB10" }, [
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(_vm._s(_vm.wholeInfo.vipRecharge || 0)),
                      ]),
                      _c("span", [_vm._v("会员卡充值")]),
                    ]),
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(_vm._s(_vm.wholeInfo.orderRealMoney || 0)),
                      ]),
                      _c("span", [_vm._v("预收款")]),
                    ]),
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(
                          _vm._s(
                            _vm.fcount(
                              [
                                _vm.wholeInfo.totalRetail,
                                _vm.wholeInfo.receivedMoney,
                              ],
                              "-"
                            ) || 0
                          )
                        ),
                      ]),
                      _c("span", [_vm._v("零售收款")]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "x-b",
                      staticStyle: { color: "#ff7e28", width: "100%" },
                    },
                    [
                      _c("span"),
                      _c("span", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v(
                          _vm._s(
                            "销售金额(" +
                              (_vm.wholeInfo.totalRetail || 0) +
                              ") - 已收订金(" +
                              (_vm.wholeInfo.receivedMoney || 0) +
                              ")"
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "totalAmount y-f" }, [
                  _c("div", { staticClass: "y-c marT20 marB20" }, [
                    _c("span", { staticClass: "titleAmount" }, [
                      _vm._v(
                        _vm._s(_vm.wholeInfo.revenueTotalTransaction || 0)
                      ),
                    ]),
                    _c("span", [_vm._v("营收总额（元）")]),
                  ]),
                  _c("div", { staticClass: "x-ac detailMoney" }, [
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(_vm._s(_vm.wholeInfo.revenueVipRecharge || 0)),
                      ]),
                      _c("span", [_vm._v("会员卡充值")]),
                    ]),
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(_vm._s(_vm.wholeInfo.revenuePredetermine || 0)),
                      ]),
                      _c("span", [_vm._v("预收款")]),
                    ]),
                    _c("div", { staticClass: "y-c" }, [
                      _c("span", { staticClass: "titleMoney" }, [
                        _vm._v(_vm._s(_vm.wholeInfo.revenueTotalRetail || 0)),
                      ]),
                      _c("span", [_vm._v("零售收款")]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "marB20" }, [
                _c("div", { staticClass: "fontS14c3 marB10 fontBold" }, [
                  _vm._v("零售"),
                ]),
                _c(
                  "div",
                  { staticClass: "x-ac" },
                  _vm._l(_vm.retailList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "y-c retail" },
                      [
                        _c("span", { staticClass: "annotateBlue" }, [
                          _vm._v(_vm._s(_vm.retailInfo[item.price] || 0)),
                        ]),
                        _c("span", [_vm._v(_vm._s(item.labale))]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "marB20" }, [
                _c("div", { staticClass: "fontS14c3 marB10 fontBold" }, [
                  _vm._v("订单"),
                ]),
                _c(
                  "div",
                  { staticClass: "x-ac" },
                  _vm._l(_vm.orderList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "x-aw order" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "titleName",
                            style: _vm.getTitleStyle(index),
                          },
                          [_vm._v(" " + _vm._s(item.payType) + " ")]
                        ),
                        _vm._l(item.list, function (itemTwo, indexTwo) {
                          return _c(
                            "div",
                            { key: indexTwo, staticClass: "y-c orderDetail" },
                            [
                              _c("span", { staticClass: "money" }, [
                                _vm._v(
                                  _vm._s(_vm.getOrderMoney(item, itemTwo) || 0)
                                ),
                              ]),
                              _c("span", { staticClass: "fontSize14" }, [
                                _vm._v(_vm._s(itemTwo.labale)),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "marB20" }, [
                _c("div", { staticClass: "fontS14c3 marB10 fontBold" }, [
                  _vm._v("会员"),
                ]),
                _c(
                  "div",
                  { staticClass: "x-w" },
                  _vm._l(_vm.viplList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "vipDetail x-fc" },
                      [
                        _c("div", { staticClass: "y-c left" }, [
                          _c("span", { staticClass: "marB5" }, [
                            _vm._v(_vm._s(_vm.shopVipInfo[item.num] || 0)),
                          ]),
                          _c("span", [_vm._v(_vm._s(item.name))]),
                        ]),
                        _c("div", { staticClass: "y-f-j right" }, [
                          _c("span", { staticClass: "marB10" }, [
                            _vm._v(
                              _vm._s(
                                "线上：" + _vm.shopVipInfo[item.onLine] || 0
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                "线下：" + _vm.shopVipInfo[item.offline] || 0
                              )
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { hideCard: true } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticStyle: { padding: "20px 10px" } }, [
              _c("div", {
                staticStyle: { width: "99%", height: "420px" },
                attrs: { id: "chart-container" },
              }),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "x-f", staticStyle: { width: "100%" } },
        [
          _c(
            "cardTitleCom",
            {
              staticStyle: { width: "50%", "margin-right": "10px" },
              attrs: { hideCard: true },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "proportion" }, [
                  _c("div", { staticClass: "fontS14c3 fontBold" }, [
                    _c(
                      "span",
                      {
                        staticClass: "cursorP",
                        class: { annotateBlue: !_vm.queryParams.isRevenue },
                        staticStyle: { "margin-right": "10px" },
                        on: {
                          click: function ($event) {
                            return _vm.handleTotal(undefined)
                          },
                        },
                      },
                      [_vm._v("收款统计")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "cursorP",
                        class: { annotateBlue: _vm.queryParams.isRevenue },
                        on: {
                          click: function ($event) {
                            return _vm.handleTotal(true)
                          },
                        },
                      },
                      [_vm._v("营收统计")]
                    ),
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "300px" },
                    attrs: { id: "chart-proportionMoney" },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticStyle: { flex: "1" }, attrs: { hideCard: true } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "proportion" }, [
                  _c("div", { staticClass: "fontS14c3 fontBold" }, [
                    _vm._v("会员"),
                  ]),
                  _c("div", { staticClass: "x-x" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "450px", height: "300px" } },
                      [
                        _c("div", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { id: "chart-vip" },
                        }),
                      ]
                    ),
                    _c("div", { staticStyle: { flex: "1" } }, [
                      _c("div", { staticClass: "x-f marB10 detailVip" }, [
                        _c("div", {
                          staticClass: "dian",
                          staticStyle: { background: "#fa8219" },
                        }),
                        _c("span", [_vm._v("新增会员")]),
                        _c("span", { staticClass: "addVipClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.newVip)),
                        ]),
                        _c("span", [_vm._v("人消费")]),
                        _c("span", { staticClass: "addVipClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.newNo)),
                        ]),
                        _c("span", [_vm._v("单,占比")]),
                        _c("span", { staticClass: "addVipClor marLR5" }, [
                          _vm._v(
                            _vm._s(_vm.vipStatisticsInfo.newProportion + "%")
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "x-f marB10 detailVip" }, [
                        _c("div", {
                          staticClass: "dian",
                          staticStyle: { background: "#9477f9" },
                        }),
                        _c("span", [_vm._v("历史会员")]),
                        _c("span", { staticClass: "historyClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.oldVip)),
                        ]),
                        _c("span", [_vm._v("人消费")]),
                        _c("span", { staticClass: "historyClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.oldNo)),
                        ]),
                        _c("span", [_vm._v("单,占比")]),
                        _c("span", { staticClass: "historyClor marLR5" }, [
                          _vm._v(
                            _vm._s(_vm.vipStatisticsInfo.oldProportion + "%")
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "x-f detailVip" }, [
                        _c("div", {
                          staticClass: "dian",
                          staticStyle: { background: "#4e95ff" },
                        }),
                        _c("span", [_vm._v("全部会员")]),
                        _c("span", { staticClass: "allVipClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.allVip)),
                        ]),
                        _c("span", [_vm._v("人消费")]),
                        _c("span", { staticClass: "allVipClor marLR5" }, [
                          _vm._v(_vm._s(_vm.vipStatisticsInfo.allNo)),
                        ]),
                        _c("span", [_vm._v("单,占比")]),
                        _c("span", { staticClass: "allVipClor marLR5" }, [
                          _vm._v(
                            _vm._s(_vm.vipStatisticsInfo.allProportion + "%")
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f", staticStyle: { width: "100%" } },
        [
          _c(
            "cardTitleCom",
            {
              staticStyle: { width: "50%", "margin-right": "10px" },
              attrs: { hideCard: true },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "goodsCategory" }, [
                  _c("div", { staticClass: "marB10 fontS14c3 fontBold" }, [
                    _vm._v("商品零售排行"),
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "420px" },
                    attrs: { id: "chart-goods" },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticStyle: { flex: "1" }, attrs: { hideCard: true } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "goodsCategory" }, [
                  _c("div", { staticClass: "marB10 fontS14c3 fontBold" }, [
                    _vm._v("类别零售排行"),
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "420px" },
                    attrs: { id: "chart-category" },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f", staticStyle: { width: "100%" } },
        [
          _c(
            "cardTitleCom",
            {
              staticStyle: { width: "50%", "margin-right": "10px" },
              attrs: { hideCard: true },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "goodsCategory" }, [
                  _c("div", { staticClass: "marB10 fontS14c3 fontBold" }, [
                    _vm._v("分店零售排行"),
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "420px" },
                    attrs: { id: "chart-shops" },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticStyle: { flex: "1" }, attrs: { hideCard: true } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "goodsCategory" }, [
                  _c("div", { staticClass: "marB10 fontS14c3 fontBold" }, [
                    _vm._v("客流排行"),
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "420px" },
                    attrs: { id: "chart-customerOrder" },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "cardTitleCom",
        { attrs: { hideCard: true } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticStyle: { padding: "20px 10px" } }, [
              _c("div", { staticClass: "fontS14c3 fontBold" }, [
                _vm._v("销售目标"),
              ]),
              _c("div", { staticClass: "x-aw goalTitle" }, [
                _c("div", { staticClass: "y-c" }, [
                  _c("span", { staticClass: "goalMoney" }, [
                    _vm._v(_vm._s(_vm.saleTargetInfo.yesterdaySaleMoney || 0)),
                  ]),
                  _c("span", { staticClass: "goalLabale" }, [
                    _vm._v("昨日销售额（元）"),
                  ]),
                ]),
                _c("div", { staticClass: "y-c" }, [
                  _c("span", { staticClass: "goalMoney" }, [
                    _vm._v(_vm._s(_vm.saleTargetInfo.thisMonthSaleMoney || 0)),
                  ]),
                  _c("span", { staticClass: "goalLabale" }, [
                    _vm._v("本月销售额（元）"),
                  ]),
                ]),
                _c("div", { staticClass: "y-c" }, [
                  _c("span", { staticClass: "goalMoney" }, [
                    _vm._v(
                      _vm._s(_vm.saleTargetInfo.thisMonthTargetSaleMoney || 0)
                    ),
                  ]),
                  _c("span", { staticClass: "goalLabale" }, [
                    _vm._v("本月目标销售额（元）"),
                  ]),
                ]),
                _c("div", { staticClass: "y-c" }, [
                  _c("span", { staticClass: "goalMoney" }, [
                    _vm._v(
                      _vm._s(
                        (_vm.saleTargetInfo.thisMonthTargetSchedule || 0) + "%"
                      )
                    ),
                  ]),
                  _c("span", { staticClass: "goalLabale" }, [
                    _vm._v("销售目标完成进度"),
                  ]),
                ]),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "420px" },
                attrs: { id: "chart-goal" },
              }),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }