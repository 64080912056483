var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-x" },
    [
      _c(
        "cardTitleCom",
        {
          staticClass: "info",
          staticStyle: { width: "400px" },
          attrs: { "card-title": "交班信息" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _vm.orderContent.payMoneyInfo
              ? _c(
                  "div",
                  { staticClass: "statementOfAccount-detail" },
                  [
                    _c(
                      "el-scrollbar",
                      { staticStyle: { height: "calc(100vh - 150px)" } },
                      [
                        _vm.curTab === "payment"
                          ? _c(
                              "div",
                              {
                                staticClass: "shifts",
                                attrs: { id: "payment" },
                              },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("交班对账单"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  [
                                    _c("el-scrollbar", [
                                      _c(
                                        "div",
                                        { staticClass: "cashier-detail" },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("店名:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(
                                                    _vm.orderContent.shopName
                                                  ) +
                                                  "】"
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("日期:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(_vm.orderContent.date)
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("收银台:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderContent.machineName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("收银员:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderContent.cashierName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("打印时间:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .printPayCheckTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("首笔小票:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .firstReceiptTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w70" },
                                              [_vm._v("末笔小票:")]
                                            ),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .lastReceiptTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "salesAmount-detail" },
                                        [
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .totalMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("交易总额:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .totalMoney
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .revenueMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("营收金额:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (_vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .revenueMoney
                                                              : "") +
                                                              "(*代表营收)"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .dealTotal
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("交易笔数:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .dealTotal
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.orderContent.payMoneyInfo
                                            .salesCollectionMoney ||
                                          _vm.orderContent.payMoneyInfo
                                            .salesRefundMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("销售金额:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (_vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .collectionMoneyTotal
                                                              : "") +
                                                              "(交易" +
                                                              (_vm.orderContent
                                                                .payMoneyInfo
                                                                ? _vm
                                                                    .orderContent
                                                                    .payMoneyInfo
                                                                    .salesCollectionMoney
                                                                : "") +
                                                              ",退货" +
                                                              (_vm.orderContent
                                                                .payMoneyInfo
                                                                ? _vm
                                                                    .orderContent
                                                                    .payMoneyInfo
                                                                    .salesRefundMoney
                                                                : "") +
                                                              ")"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.orderContent.payMoneyInfo
                                            .bookCollectionMoney ||
                                          _vm.orderContent.payMoneyInfo
                                            .bookRefundMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("预订金额:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (_vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .bookMoneyTotal
                                                              : "") +
                                                              "(交易" +
                                                              (_vm.orderContent
                                                                .payMoneyInfo
                                                                ? _vm
                                                                    .orderContent
                                                                    .payMoneyInfo
                                                                    .bookCollectionMoney
                                                                : "") +
                                                              ",退款" +
                                                              (_vm.orderContent
                                                                .payMoneyInfo
                                                                ? _vm
                                                                    .orderContent
                                                                    .payMoneyInfo
                                                                    .bookRefundMoney
                                                                : "") +
                                                              ")"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeCollectionMoney ||
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeRefundCollectionMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w70",
                                                    },
                                                    [_vm._v("充值金额:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .rechargeCollectionTotal +
                                                              "(交易" +
                                                              _vm.orderContent
                                                                .payMoneyInfo
                                                                .rechargeCollectionMoney +
                                                              ",退款" +
                                                              _vm.orderContent
                                                                .payMoneyInfo
                                                                .rechargeRefundCollectionMoney +
                                                              ")"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "Collection-detail" },
                                        [
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .salesCollectionNum
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("销售收款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (_vm.orderContent
                                                            .payMoneyInfo
                                                            ? _vm.orderContent
                                                                .payMoneyInfo
                                                                .salesCollectionMoney
                                                            : "") +
                                                            " (" +
                                                            (_vm.orderContent
                                                              .payMoneyInfo
                                                              ? _vm.orderContent
                                                                  .payMoneyInfo
                                                                  .salesCollectionNum
                                                              : "") +
                                                            "笔)"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .salesCollectionDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                { key: item.uuid },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row notBold",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "label w120",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                "" +
                                                                  (item.isRevenue
                                                                    ? "*"
                                                                    : "") +
                                                                  item.payModeName +
                                                                  ":"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "val" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.payMoney
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    item.depositDetails,
                                                    function (item_val) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item_val.uuid,
                                                          staticClass:
                                                            "row-children",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label w120",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item_val.sourceOrderTypeName +
                                                                      ":"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "val",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item_val.payMoney
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .salesRefundNum
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("销售退款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.orderContent
                                                            .payMoneyInfo
                                                            .salesRefundMoney +
                                                            " (" +
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .salesRefundNum +
                                                            "笔)"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .salesRefundDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .bookCollectionNum
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("预定金收款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.orderContent
                                                            .payMoneyInfo
                                                            .bookCollectionMoney +
                                                            " (" +
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .bookCollectionNum +
                                                            "笔)"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .bookCollectionDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .bookRefundNum
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("预定金退款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.orderContent
                                                            .payMoneyInfo
                                                            .bookRefundMoney +
                                                            " (" +
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .bookRefundNum +
                                                            "笔)"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .bookRefundDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeCollectionNum
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("充值收款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.orderContent
                                                            .payMoneyInfo
                                                            .rechargeCollectionMoney +
                                                            " (" +
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .rechargeCollectionNum +
                                                            "笔)"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .rechargeCollectionDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeRefundCollectionMoney ||
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeRefundCollectionNum ||
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeRefundCollectionDetails
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("充值退款金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .rechargeGiveMoney +
                                                              "(赠送" +
                                                              _vm.orderContent
                                                                .payMoneyInfo
                                                                .rechargeNormalGiveMoney +
                                                              ",退赠" +
                                                              _vm.orderContent
                                                                .payMoneyInfo
                                                                .rechargeRefundGiveMoney +
                                                              ")"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .rechargeRefundCollectionDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.orderContent.payMoneyInfo &&
                                          _vm.orderContent.payMoneyInfo
                                            .rechargeGiveMoney
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [_vm._v("充值赠送金额:")]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "" +
                                                            _vm.orderContent
                                                              .payMoneyInfo
                                                              .rechargeGiveMoney
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .rechargeGiveDetails,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.uuid,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "payMethod-detail" },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w120" },
                                              [_vm._v("付款方式汇总")]
                                            ),
                                          ]),
                                          _vm._l(
                                            _vm.orderContent.payMoneyInfo
                                              .payMethodCollectDetails,
                                            function (item, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.payModeId + "." + i,
                                                  staticClass: "row notBold",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "label w120",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              (item.isRevenue
                                                                ? "*"
                                                                : "") +
                                                              item.payModeName +
                                                              ":"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "val" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.payMoney)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm.orderContent.payMoneyInfo &&
                                      _vm.orderContent.payMoneyInfo
                                        .TemporaryDiscount
                                        ? _c("div", { staticClass: "total" }, [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "label w105" },
                                                [_vm._v("临时优惠金额:")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "val" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.orderContent
                                                          .payMoneyInfo
                                                          .TemporaryDiscount
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm.orderContent.payMoneyInfo &&
                                            _vm.orderContent.payMoneyInfo
                                              .cashTatal
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "label w105",
                                                      },
                                                      [_vm._v("应缴现金:")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "val" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.orderContent
                                                                .payMoneyInfo
                                                                .cashTatal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("div", { staticClass: "signature" }, [
                                        _c("div", { staticClass: "row w85" }, [
                                          _c("div", { staticClass: "label" }, [
                                            _vm._v("交款人签字:"),
                                          ]),
                                          _c("div", { staticClass: "val" }),
                                        ]),
                                        _c("div", { staticClass: "row w85" }, [
                                          _c("div", { staticClass: "label" }, [
                                            _vm._v("收款人签字:"),
                                          ]),
                                          _c("div", { staticClass: "val" }),
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "label w85 remark" },
                                            [_vm._v("备注:")]
                                          ),
                                          _c("div", { staticClass: "val" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.orderContent.payMoneyInfo
                                                    .remark
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "shopName" }, [
                                        _vm._v("【深圳市隼云科技软件】"),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "goods", attrs: { id: "goods" } },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("商品对账单"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  [
                                    _c("el-scrollbar", [
                                      _c(
                                        "div",
                                        { staticClass: "cashier-detail" },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("店名")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(
                                                    _vm.orderContent.shopName
                                                  ) +
                                                  "】"
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("日期")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(_vm.orderContent.date)
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("收银台")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderContent.machineName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("收银员")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderContent.cashierName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("打印时间")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .printGoodsCheckTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("首笔小票")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .firstReceiptTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("末笔小票")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent
                                                      .lastReceiptTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "label w77" },
                                              [_vm._v("备注")]
                                            ),
                                            _vm._v(" : "),
                                            _c("div", { staticClass: "val" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderContent.goodsInfo
                                                      .remark
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "goods-detail" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "goodsTitle-list" },
                                            [
                                              _c(
                                                "strong",
                                                { staticClass: "nameTitle" },
                                                [_vm._v(" 品名 ")]
                                              ),
                                              _c(
                                                "strong",
                                                { staticClass: "amountTitle" },
                                                [_vm._v(" 数量 ")]
                                              ),
                                              _c(
                                                "strong",
                                                {
                                                  staticClass: "salePriceTitle",
                                                },
                                                [_vm._v(" 售价 ")]
                                              ),
                                              _c(
                                                "strong",
                                                { staticClass: "totalTitle" },
                                                [_vm._v(" 小计 ")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "goodsList" },
                                            _vm._l(
                                              _vm.orderContent.goodsInfo
                                                .goodsDetails,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.uuid,
                                                    staticClass: "goodsItem",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "goodsName",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.goodsName)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "goodsRow x-f",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goodsNo",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.goodsNo
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goodsAmount",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.unitQty +
                                                                    "(" +
                                                                    item.unitName +
                                                                    ")"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goodsSalePrice",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.unitPrice
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "goodsTotal",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.unitMoney
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "footer-total" }, [
                                  _c("strong", { staticClass: "label" }, [
                                    _vm._v("合计:"),
                                  ]),
                                  _c("strong", { staticClass: "amount" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderContent.goodsInfo.goodsTotalQty
                                      )
                                    ),
                                  ]),
                                  _c("strong", { staticClass: "totalPrices" }, [
                                    _vm._v(
                                      _vm._s(
                                        "¥" +
                                          _vm.orderContent.goodsInfo
                                            .goodsTotalMoney
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("TablePage", {
        ref: "tablePage",
        staticStyle: { width: "calc(100% - 420px)" },
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }