<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  orderListAPI,
  orderListSummaryAPI,
  getShopPredetermineDateList,
  getShopPredetermineDateListSummary,
  getShopPredetermineShopList,
  getShopPredetermineShopListSummary,
  getShopPredetermineGoodsList,
  getShopPredetermineGoodsListSummary,
  getShopPreOrderClientContactList
} from "@/api/shop/report/posReserveQuery"; // 销售列表
import { getDicts } from "@/api/system/dict/data";
export default {
  name: "PosReserveQuery",
  dicts: ["fetch_type", "order_status"],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店客流量查询",
        rowKey: "billIda",
        search: [
          {
            label: "预定时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "receiverNames", label: "收货人" },
              { filter: "receiverPhones", label: "收货人电话" },
              { filter: "billNos", label: "预订小票号" }
            ],
          },
          {
            label: "取货时间",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({
              pickupBeginBillDate: a,
              pickupEndBillDate: b,
            }),
          },
          {
            type: "local",
            label: "状态",
            model: "",
            filter: "orderStatuss",
            hide: () => this.options?.tabColumnType != "shopOrderInfo",
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: "order_status",
              label: "dictLabel",
              value: "dictValue",
            },
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "predetermineShopIds",
              label: "预定门店",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择预定门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              label: "取货门店",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择取货门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "summary",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "local",
            label: "配送模式",
            model: "",
            filter: "fetchTypes",
            hide: () => this.options?.tabColumnType != "shopOrderInfo",
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: "fetch_type",
              label: "dictLabel",
              value: "dictValue",
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        tabsColumns: [
          {
            defaultBody: { searchType: "detail" },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            uuid: "8ada0166-f556-4cfe-1dbd-70f081200021",

            title: "预订单明细查询",
            type: "detail",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport",
              exportName: "预订单明细查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
              },
              {
                prop: "saleMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "machining",
                label: "加值属性",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "distillBillNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "reachDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "distillBillDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ['unitOldMoney', "saleMoney", "unitQty"],
          },
          {
            defaultBody: { searchType: "summary" },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            uuid: "7dad0967-870d-30cc-e00a-bd58f778e99d",
            title: "预订单查询",
            type: "summary",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport",
              exportName: "预订单查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "distillBillNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "reachDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "distillBillDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ["saleMoney"],
          },
          {
            defaultBody: { searchType: "payment" },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            uuid: "d9ff7f71-9922-1268-e1ab-39c36e146454",
            title: "预订收款查询",
            type: "payment",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport",
              exportName: "预订收款查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "distillBillNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "reachDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "distillBillDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "notPickedPayTypes",
                align: "center",
                label: "预收款",
                children: () =>
                  this.options.list?.[0]?.notPickedPayTypes?.map?.((x) => ({
                    ...x,
                    label: x.payModeName,
                    prop: `notPickedPayDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.notPickedPayDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
              {
                prop: "pickUpPayTypes",
                align: "center",
                label: "零售收款",
                children: () =>
                  this.options.list?.[0]?.pickUpPayTypes?.map?.((x) => ({
                    ...x,
                    label: x.payModeName,
                    prop: `pickUpPayDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.pickUpPayDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ["saleMoney"],
          },
          {
            defaultBody: { searchType: "refund" },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            uuid: "9e5874b0-07e4-5bd7-c888-063974c545ac",
            title: "预订退款查询",
            type: "refund",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport",
              exportName: "预订退款查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "distillBillNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "reachDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "distillBillDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "退款方式",
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.((x) => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ["saleMoney"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineDateList,
            getSummaryApi: getShopPredetermineDateListSummary,
            uuid: "cbdfa243-d0dd-0dbf-df82-6c7f3c7e6b9e",
            title: "预订查询汇总（按日期）",
            type: "date",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineDateListExport",
              exportName: "预订查询汇总（按日期）",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum", "unitQty"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineShopList,
            getSummaryApi: getShopPredetermineShopListSummary,
            uuid: "0c557145-c044-ef3f-4b21-d554717e6b8a",
            title: "预订查询汇总（按门店）",
            type: "shop",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineShopListExport",
              exportName: "预订查询汇总（按门店）",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 155,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum", "unitQty"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineGoodsList,
            getSummaryApi: getShopPredetermineGoodsListSummary,
            uuid: "230746ca-cf63-4c46-63fa-2ee92795f688",

            title: "预订查询汇总（按商品）",
            type: "shop",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPredetermineGoodsListExport",
              exportName: "预订查询汇总（按商品）",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 155,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 155,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 155,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 155,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum", "unitQty"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPreOrderClientContactList,
            uuid: "230746ca-cf63-4c46-63fa-2ee92795f686",
            title: "门店预定客户信息",
            type: "shopOrderInfo",
            exportOption: {
              fastExportUrl:
                "/api/system/shop/base/predetermineOrder/getShopPreOrderClientContactListExport",
              exportName: "门店预定客户信息",
            },
            columns: [
              {
                prop: "receiverName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "电话",
                minWidth: 120,
              },
              {
                prop: "receiverAddress",
                label: "地址",
                minWidth: 180,
              },
              {
                prop: "orderCreateTime",
                label: "预定时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预定小票号",
                minWidth: 180,
              },
              {
                prop: "orderShopName",
                label: "预定门店",
                minWidth: 155,
              },
              {
                prop: "distillBillDateStr",
                label: "取货日期",
                minWidth: 155,
              },
              {
                prop: "distillBillCnDateStr",
                label: "取货日期(农历)",
                minWidth: 155,
              },
              {
                prop: "fetchTime",
                label: "取货时段",
                minWidth: 120,
              },
              {
                prop: "fetchShopName",
                label: "取货门店",
                minWidth: 155,
              },
              {
                prop: "handPointSize",
                label: "磅数",
                minWidth: 80,
                sortable: true,
              },
              {
                prop: "handSize",
                label: "尺寸",
                minWidth: 80,
                sortable: true,
              },
              {
                prop: "handStyle",
                label: "样式",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              }
            ],
          },
        ],
      },
      payTypes: [], //付款方式
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          this.$router.push({
            name: "posReserveQueryDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
